import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import './assets/tailwind.css'
import Notifications from 'notiwind'
import { createPinia } from 'pinia'

import  i18n from './i18n'

const pinia = createPinia();

createApp(App).use(pinia).use(router).use(i18n).use(Notifications).mount('#app')

