import http from '../common/http-common'



class settingService {

    showEditSettingById(id: null) {
        return http.get(`Settings/${id}`)
    }
    editUser(id: null, trailer: object) {
        return http.put(`Settings/${id}`,trailer)
    }
    getSettings() {
        return http.get('Settings')
    }    
}
export default new settingService()