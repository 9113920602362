import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TracksView from '../views/TracksView.vue'
import DocumentTypeView from '../views/DocumentTypeView.vue'
import DocumentView from '../views/DocumentView.vue'
import DriverView from '../views/DriverView.vue'
import ListsDriverView from '../views/ListsDriverView.vue'
import ShipmentView from '../views/ShipmentView.vue'
import UserView from '../views/UserView.vue'
import StatusView from '../views/StatusView.vue'
import DashboardView from '../views/DashboardView.vue'
import LoginView from '../views/LoginView.vue'
import SettingsView from '../views/SettingsView.vue'
import TrailerView from '../views/TrailerView.vue'
import TrucksView from '../views/TracksView.vue'
import RegistreView from '../views/RegistreView.vue'
import CompanyView from '../views/CompanyView.vue'
import RoleView from '../views/RoleView.vue'
import RequestUserView from '../views/RequestUserView.vue'
import SubscriptionView from '../views/SubscriptionView.vue'

 
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registre',
    name: 'registre',
    component: RegistreView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView ,  
  },
  {
    path: '/Statistics',
    name: 'Statistics',
    component: DashboardView, 
  },
  {
    path: '/Tracks',
    name: 'Tracks',
    component: TracksView ,  
  },
  {
    path: '/DocumentType',
    name: 'DocumentType',
    component: DocumentTypeView ,
  },
  {
    path: '/Document',
    name: 'Document',
    component: DocumentView , 
  }, 
  {
    path: '/Driver',
    name: 'Driver',
    component: DriverView ,
  }, 
  {
    path: '/ListsDrivers',
    name: 'ListsDrivers',
    component: ListsDriverView , 
  }, 
  {
    path: '/Shipment',
    name: 'Shipment',
    component: ShipmentView , 
  }, 
  {
    path: '/User',
    name: 'User',
    component: UserView ,  meta: { requiresAuth: true }
  },
  {
    path: '/RequestUser',
    name: 'RequestUser',
    component: RequestUserView ,  meta: { requiresAuth: true }
  }, 
  {
    path: '/Status',
    name: 'Status',
    component: StatusView , 
  },  
  {
    path: '/Settings',
    name: 'Settings',
    component: SettingsView ,  meta: { requiresAuth: true }
  }, 
  {
    path: '/Trailer',
    name: 'Trailer',
    component: TrailerView ,  meta: { requiresAuth: true }
  },
  {
    path: '/Track',
    name: 'Track',
    component: TrucksView ,  meta: { requiresAuth: true }
  },
  {
    path: '/Companies',
    name: 'Companies',
    component: CompanyView ,  meta: { requiresAuth: true }
  },
  {
    path: '/roles',
    name: 'role',
    component: RoleView ,  meta: { requiresAuth: true }
  },
  {
    path: '/Subscription',
    name: 'Subscription',
    component: SubscriptionView ,  meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('token'); // Replace with your auth check

  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('');
  } else {
    next();
  }
});
export default router
