<template>
    <div v-if="showPageThanks" class="flex h-screen items-center justify-center">
        <div>
            <div class="flex flex-col items-center space-y-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-28 w-28 text-[#2B8BD1]" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <h1 class="text-4xl font-bold">Thank You !</h1>
                <p>Thank you for your interest! Check your email for a link to the guide.</p>
                <a
                    class="inline-flex items-center rounded border border-[#2B8BD1] bg-[#2B8BD1] px-4 py-2 text-white hover:bg-[#2B8BD1] focus:outline-none focus:ring">
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-3 w-3" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                    <span class="text-sm font-medium"> Login </span>
                </a>
            </div>
        </div>
    </div>
    <div>
    <div v-if="!showPageThanks" class="min-h-screen bg-gray-100 text-gray-800 antialiased px-4 py-6 flex flex-col justify-center sm:py-12">
        <div class="relative py-3 sm:max-w-xl w-2/5 mx-auto text-center">
            <span class="text-2xl font-bold">Registre your account</span>
            <div class="relative mt-4 bg-white shadow-md sm:rounded-lg text-left">
                <div class="h-2 bg-[#2B8BD1] rounded-t-md"></div>
                <div class="py-6 px-8">
                    <label class="block font-semibold">FirstName</label>
                    <input type="text" v-model="registersInfo.firstName"  placeholder="firstName" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-[#2B8BD1] rounded-md">
                    <label class="block font-semibold">LastName</label>
                    <input type="text" v-model="registersInfo.lastName"  placeholder="LastName" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-[#2B8BD1] rounded-md">
                    <label class="block font-semibold">Username</label>
                    <input type="text" v-model="registersInfo.username"  placeholder="Username" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-[#2B8BD1] rounded-md">
                    <label class="block font-semibold">Email</label>
                    <input type="text" v-model="registersInfo.email"  placeholder="Email" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-[#2B8BD1] rounded-md">

                    <label class="block mt-3 font-semibold">Password</label>
                    <input type="password" v-model="registersInfo.password" placeholder="Password" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-[#2B8BD1] rounded-md">

                    <div class="flex justify-between items-baseline">
                        <button @click="saveInfo" class="mt-4 bg-[#2B8BD1] text-white py-2 px-6 rounded-lg hover:bg-[#2B8BD1]">Registre</button>
                        <!-- <a href="#" class="text-sm hover:underline">Forgot password?</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script lang="ts" setup>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import UserService from '../services/userService'

const router = useRouter();
const showPageThanks = ref(false)
const registersInfo  = ref({
    firstName:ref(''),
    lastName:ref(''),
    username:ref(''),
    password:ref(''),
    email:ref(''),
})
const saveInfo = () => {
    console.log(registersInfo.value)
    UserService.addRegistre(registersInfo.value)
    .then(( response: any ) => {
       console.log(response)
       showPageThanks.value = true
    })
    .catch((error:any) => {
     console.log(error)
    
   });
}; 
</script>