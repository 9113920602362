<template>
    <div class="flex h-screen overflow-hidden">
        <!-- ===== Sidebar Start ===== -->
        <SidebarView />
        <!-- ===== Sidebar End ===== -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden" >
            <!-- ===== Header Start ===== -->
            <HeaderView />
            <!-- ===== Header Start ===== -->
            <main>
                <div class="w-11/12 mx-auto sm:px-8">
                    
                    <h2 class="text-2xl font-semibold leading-tight">Settings</h2>

                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                Company
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(setting, index) in settings" :key="index" class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">

                                            <td class="px-6 py-4">
                                                
                                                <div class="md:flex md:items-center">
                                                    <div class="md:w-1/3">
                                                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                                                        {{ setting.key }}
                                                    </label>
                                                    </div>
                                                    <div class="md:w-2/3">
                                                        <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" value="Jane Doe">
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="px-6 py-4">
                                                {{ setting.value }}
                                            </td>
                                            <td class="px-6 py-4">
                                                <a href="#" @click="showsettingsEditId(setting.id)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>

                </div>
            </main>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref , onMounted } from 'vue'
import SidebarView from './partials/SidebarView.vue'
import HeaderView from './partials/HeaderView.vue'
import settingService from '@/services/settingService'

const settings = ref('')
const showsettingsEdit = ref({
    key: ref(''),
    value: ref(''),
    id:ref(null)
})
const showIdSettings = ref(null)
const showInputSetting = ref(false)


const getSetting = () => {
  settingService.getSettings()
  .then(( response: any ) => {
    settings.value = response.data
    console.log(settings.value)
  })
  .catch((error:any) => {
    console.log(error)
  });
}

const showsettingsEditId  = (item : any) => {
    showIdSettings.value =item
  settingService.showEditSettingById(showIdSettings.value)
    .then(( response: any ) => {
        console.log(response.data)
        showsettingsEdit.value.key = response.data.key
        showsettingsEdit.value.value = response.data.value
        showInputSetting.value = true

    })
    .catch((error:any) => {
      console.log(error)
    });
}


onMounted(() => {
    getSetting()
})
</script>