import http from '../common/http-common'



class DriverListService {

    addDriverList(data: object) {
        return http.post('Driver', data)
    }
    deleteDriverListById(id: null) {
        return http.delete(`Driver/${id}`)
    }
    showEditDriverListById(id: null) {
        return http.get(`Driver/${id}`)
    }
    editDriverList(id: null, track: object) {
        return http.put(`Driver/${id}`,track)
    }

    getDriverList() {
        return http.get('Driver')
    }    
}
export default new DriverListService()
