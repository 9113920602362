import http from '../common/http-common'



class StatutService {

    addStatus(data: object) {
        return http.post('Status', data)
    }
    deleteStatusById(id: null) {
        return http.delete(`Status/${id}`)
    }
    showEditStatusById(id: null) {
        return http.get(`Status/${id}`)
    }
    editStatus(id: null, status: object) {
        return http.put(`Status/${id}`,status)
    }
    getStatus() {
        return http.get('Status')
    }    
}
export default new StatutService()
