import http from '../common/http-common'



class shipmentService {

    addShipment(data: object) {
        return http.post('Shipment', data)
    }
    deleteShipmentById(id: null) {
        return http.delete(`Shipment/${id}`)
    }
    showEditShipmentById(id: null) {
        return http.get(`Shipment/${id}`)
    }
    editShipment(id: null, shipment: object) {
        return http.put(`Shipment/${id}`,shipment)
    }
    getShipment() {
        return http.get('Shipment')
    }    
}
export default new shipmentService()
