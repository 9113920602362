<template>
      <div class="flex h-screen overflow-hidden">
        <!-- ===== Sidebar Start ===== -->
        <SidebarView />
        <!-- ===== Sidebar End ===== -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden" >
            <!-- ===== Header Start ===== -->
            <HeaderView />
            <!-- ===== Header Start ===== -->
            <main>
              <div class="w-11/12 mx-auto px-4 sm:px-8">
                <div v-if="isModalDelete" class="fixed inset-0 flex items-center justify-center z-50">
                  <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                  <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                      <button  @click="cancelDelete" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
                          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span class="sr-only">{{ $t('Closemodal') }}</span>
                      </button>
                      <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      <p class="mb-4 text-gray-500 dark:text-gray-300">{{ $t('AreyousureyouwanttodeletethisShipment') }}</p>
                      <div class="flex justify-center items-center space-x-4">
                          <button @click="cancelDelete" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                              {{ $t('NoCancel') }}
                          </button>
                          <button @click="deleteItem" class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                              {{ $t('YesSure') }}
                          </button>
                      </div>
                  </div>
                </div>
                <div v-if="!showAddShipment" class="py-8">
                  <div class="w-full mx-auto grid grid-cols-1 lg:grid-cols-3 justify-between gap-2">
                    <div class="flex gap-5">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4294 0H12.5583C12.6615 0.0234399 12.7443 0.0500052 12.806 0.0812585C16.7791 2.06818 20.7522 4.05355 24.7268 6.03657C24.8331 6.0897 24.9112 6.18033 24.9596 6.30769C24.9893 6.38426 25.0042 6.49755 25.0034 6.64679C25.0003 8.39072 24.9995 10.1354 25.0018 11.8794C25.0018 12.5349 24.0634 12.7365 23.8142 12.131C23.7689 12.0208 23.7462 11.8301 23.747 11.5582C23.7494 10.2417 23.7501 8.9228 23.7478 7.60157C23.7478 7.58907 23.7353 7.58204 23.7243 7.58673C20.1248 9.3877 16.5228 11.1894 12.9193 12.9928C12.649 13.1279 12.4685 13.1912 12.2052 13.0592C10.4597 12.1794 8.71106 11.3035 6.9601 10.4308C6.94057 10.4206 6.93119 10.4269 6.93119 10.448C6.9265 11.6379 6.92572 12.8271 6.92963 14.0171C6.93119 14.5242 6.43973 14.8195 5.99047 14.5875C5.74826 14.4624 5.68184 14.2124 5.68106 13.9327C5.68028 12.5583 5.68028 11.1879 5.68106 9.82212C5.68106 9.79946 5.6709 9.78305 5.65059 9.77289L1.27591 7.5836C1.26341 7.57813 1.24935 7.58673 1.24935 7.60079V17.7229C1.24935 17.7433 1.25872 17.7589 1.2767 17.7698C4.88176 19.7732 8.48682 21.7773 12.0911 23.7806C12.9201 24.2408 12.331 25.3472 11.4723 24.869C7.75471 22.8 4.03558 20.7326 0.316439 18.666C0.146109 18.5715 0.0406292 18.4168 0 18.2035V6.40848C0.130482 6.04594 0.352381 6.00219 0.66335 5.84748C4.51297 3.91994 8.36337 1.99318 12.2138 0.0679758C12.2661 0.0414106 12.338 0.0195333 12.4302 0M2.06115 6.54521C2.04631 6.55224 2.04631 6.57334 2.06115 6.58037L6.29519 8.69934C6.30066 8.70247 6.30769 8.70247 6.31238 8.69934L16.7424 3.48083C16.7572 3.4738 16.7572 3.4527 16.7424 3.44567L12.5052 1.32904C12.4997 1.32592 12.4927 1.32592 12.488 1.32904L2.06115 6.54521ZM7.73674 9.38613C7.72346 9.39317 7.72346 9.4127 7.73674 9.41973L12.4943 11.8012C12.4997 11.8036 12.5052 11.8036 12.5107 11.8012L22.9485 6.58037C22.9618 6.57334 22.9618 6.55381 22.9485 6.54678L18.1847 4.16606C18.1792 4.16371 18.1738 4.16371 18.1683 4.16606L7.73674 9.38613Z" fill="black"/>
                        <path d="M30.0008 21.5983V22.153C29.9226 23.8899 29.3827 25.4182 28.3795 26.7371C26.8356 28.767 24.7541 29.8546 22.1343 29.9999H21.6015C19.1582 29.8671 17.1697 28.8928 15.6376 27.077C14.6218 25.8737 14.0132 24.4744 13.8139 22.8789C13.1287 17.4088 18.0269 12.8364 23.4524 13.9045C27.1817 14.639 29.8226 17.826 30.0008 21.5983ZM28.7483 21.8749C28.7483 18.0792 25.6706 15.0023 21.8757 15.0023C18.0808 15.0023 15.0031 18.0799 15.0031 21.8749C15.0031 25.6698 18.0808 28.7475 21.8757 28.7475C25.6706 28.7475 28.7483 25.6698 28.7483 21.8749Z" fill="black"/>
                      </svg>
                      <h2 class="text-2xl font-semibold leading-tight">{{ $t('Shipment') }}</h2>
                    </div>
                    <div class="flex justify-center pb-1 lg:pb-4  bg-white dark:bg-gray-900">
                      <div class="w-full">
                          <label for="table-search" class="sr-only">Search</label>
                          <div class="relative mt-1">
                              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                  </svg>
                              </div>
                              <input type="text" id="table-search" class="w-full block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg  bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
                          </div>
                      </div>
                    </div>
                    <div class="flex  justify-start lg:justify-end mb-3">
                      <button  @click="showFormShipment" :class="roleClass" class="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-base font-bold p-1 text-center inline-flex items-center px-3 py-2 lg:py-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                          <path d="M11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44771 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z"/>
                        </svg>
                        {{ $t('AddNewShipment') }}
                      </button>
                    </div>
                  </div>
                  <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal text-center ">
                        <thead :class="roleClass">
                          <tr >
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold  uppercase tracking-wider">
                              #ID
                            </th>
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold  uppercase tracking-wider">
                              {{ $t('TrackingID') }}
                            </th>
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('Depart') }}
                            </th>
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('Destination') }}
                            </th>
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibolduppercase tracking-wider">
                              {{ $t('Status') }}
                            </th> 
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('Driver') }}
                            </th> 
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('Track') }}
                            </th> 
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('Trailer') }}
                            </th> 
                            <th class="px-5 py-5 border-b-2  text-white text-center text-xs font-semibold uppercase tracking-wider">
                              {{ $t('ACTION') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="shipment in shipments" :key="shipment.id">
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.id }} </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.identifier }} </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.locationStart }}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.locationEnd }}  </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.statusName }}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.userDriverFullName }}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.trackIdentifier }}</p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ shipment.userDriverFullName }}</p>
                            </td> 
                            <td>
                              <div class="w-fit	m-auto">
                                <div class="flex flex-row text-center items-center justify-items-center">
                                  <p class="text-gray-600 whitespace-no-wrap">
                                  <svg @click="showView" class="m-auto cursor-pointer" width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z" fill="#1C274C"/>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.25C7.48587 3.25 4.44529 5.9542 2.68057 8.24686L2.64874 8.2882C2.24964 8.80653 1.88206 9.28392 1.63269 9.8484C1.36564 10.4529 1.25 11.1117 1.25 12C1.25 12.8883 1.36564 13.5471 1.63269 14.1516C1.88206 14.7161 2.24964 15.1935 2.64875 15.7118L2.68057 15.7531C4.44529 18.0458 7.48587 20.75 12 20.75C16.5141 20.75 19.5547 18.0458 21.3194 15.7531L21.3512 15.7118C21.7504 15.1935 22.1179 14.7161 22.3673 14.1516C22.6344 13.5471 22.75 12.8883 22.75 12C22.75 11.1117 22.6344 10.4529 22.3673 9.8484C22.1179 9.28391 21.7504 8.80652 21.3512 8.28818L21.3194 8.24686C19.5547 5.9542 16.5141 3.25 12 3.25ZM3.86922 9.1618C5.49864 7.04492 8.15036 4.75 12 4.75C15.8496 4.75 18.5014 7.04492 20.1308 9.1618C20.5694 9.73159 20.8263 10.0721 20.9952 10.4545C21.1532 10.812 21.25 11.2489 21.25 12C21.25 12.7511 21.1532 13.188 20.9952 13.5455C20.8263 13.9279 20.5694 14.2684 20.1308 14.8382C18.5014 16.9551 15.8496 19.25 12 19.25C8.15036 19.25 5.49864 16.9551 3.86922 14.8382C3.43064 14.2684 3.17374 13.9279 3.00476 13.5455C2.84684 13.188 2.75 12.7511 2.75 12C2.75 11.2489 2.84684 10.812 3.00476 10.4545C3.17374 10.0721 3.43063 9.73159 3.86922 9.1618Z" fill="#1C274C"/>
                                  </svg>  
                                  </p>
                                  <svg  @click="showEditShipment(shipment.id)" class="cursor-pointer" width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#07d514" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#07d514" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                  <svg  @click="confirmDelete(shipment.id)" class="cursor-pointer" width="30px" height="30px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#e60000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 12V17" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 12V17" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <form v-if="showAddShipment" class="bg-white shadow-md rounded sm:px-2 md:px-8   pt-6 pb-8 mb-4">
                  <h3 v-if="!showFormEditShipment" class="text-2xl font-semibold mb-5 leading-tight">{{ $t('AddNewShipment') }}</h3>
                  <h3 v-else>{{ $t('EditNewShipment') }}</h3>

                  <div class="flex flex-wrap -mx-3">
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                    ID
                                  </label>
                              </div>
                              <div class="md:w-full">
                                <input v-if="!showFormEditShipment"  v-model="infoShipment.identifier" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="inline-full-name" type="text">
                                  <input v-else v-model="showShipmentEdit.identifier" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="inline-full-name" type="text">
                              </div>
                          </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6 sm:gap-4 md:gap-4">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold md:text-left  py-3 mb-3" for="inline-full-name">
                                    {{ $t('Depart') }}
                                  </label>
                              </div>
                              <div class="md:w-full md:flex md:items-center md:gap-5">
                                <div class="md:w-1/2">
                                  <div>
                                      <select v-model="locationStartCountry" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option >{{ $t('SelectCity') }}</option> 
                                        <option v-for="country in departCountry" :key="country" :value="country">
                                          {{ country.name }}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="md:w-1/2 ">
                                  <div>
                                        <select v-model="locationStartCity" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option >{{ $t('SelectCity') }}</option> 
                                          <option v-for="city in departCity" :key="city" :value="city">
                                            {{ city.name }}
                                          </option>
                                        </select>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>


                  <div class="flex flex-wrap -mx-3">
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                      {{ $t('TrackingID') }}
                                  </label>
                              </div>
                              <div class="md:w-full">
                                  <input v-if="!showFormEditShipment"  v-model="infoShipment.trackIdentifier" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="inline-full-name" type="text">
                                  <input v-else v-model="showShipmentEdit.trackIdentifier" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="inline-full-name" type="text">
                              </div>
                          </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6 sm:gap-4 md:gap-3">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold md:text-left  py-3 mb-3" for="inline-full-name">
                                      {{ $t('Destination') }}
                                  </label>
                              </div>
                              <div class="md:w-full md:flex md:items-center md:gap-5">
                                <div class="md:w-1/2">
                                  <div>
                                      <select v-model="locationEndCountry" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option > {{ $t('SelectCity') }}</option> 
                                        <option v-for="country in destinationCountry" :key="country" :value="country">
                                          {{ country.name }}
                                        </option>
                                      </select>
                                  </div>
                                </div>
                                <div class="md:w-1/2 ">
                                    <div>
                                        <select v-model="locationEndCity" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option > {{ $t('SelectCity') }}</option> 
                                          <option v-for="city in destinationCity" :key="city" :value="city">
                                            {{ city.name }}
                                          </option>
                                        </select>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>








                  <div class="flex flex-wrap -mx-3">
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                      {{ $t('Trailer') }}
                                  </label>
                              </div>
                              <div class="md:w-full">
                                  <div>
                                      <select v-if="!showFormEditShipment"  v-model="trailersInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option disabled value=""> {{ $t('SelectTrailer') }}</option> 
                                          <option v-for="item in trailers" :key="item" :value="item">
                                            {{ item.identifier }}
                                          </option>
                                      </select>
                                      <select v-else  v-model="trailersInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option disabled value=""> {{ $t('SelectTrailer') }}</option> 
                                          <option v-for="item in trailers" :key="item.id" :value="item.id">
                                            {{ item.identifier }}
                                          </option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                    {{ $t('Status') }} 
                                  </label>
                              </div>
                              <div class="w-full md:w-2/3">
                                  <div>
                                      <select  v-if="!showFormEditShipment" v-model="statusInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option >{{ $t('SelectStatus') }}</option>  
                                        <option v-for="statut in statuts" :key="statut" :value="statut">
                                            {{ statut.name }}
                                        </option>
                                      </select>
                                      <select v-else v-model="statusInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option >{{ $t('SelectStatus') }}</option>  
                                        <option v-for="statut in statuts" :key="statut.id" :value="statut.id">
                                            {{ statut.name }}
                                        </option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="flex flex-wrap -mx-3">
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                    {{ $t('Track') }}
                                  </label>
                              </div>
                              <div class="md:w-full">
                                  <div>
                                      <select v-if="!showFormEditShipment" v-model="trackInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option >{{ $t('SelectTrack') }}</option> 
                                        <option v-for="track in tracks" :key="track" :value="track">
                                          {{ track.identifier }}
                                        </option>
                                      </select>
                                      <select v-else v-model="trackInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                        <option >{{ $t('SelectTrack') }}</option> 
                                        <option v-for="track in tracks" :key="track.id" :value="track.id">
                                          {{ track.identifier }}
                                        </option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                          <div class="md:flex md:items-center mb-6">
                              <div class="md:w-[20%]">
                                  <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-full-name">
                                    {{ $t('Driver') }}
                                  </label>
                              </div>
                              <div class="w-full md:w-2/3">
                                  <div>
                                      <select v-if="!showFormEditShipment"  v-model="driverInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option >{{ $t('SelectDriver') }}</option>
                                          <option v-for="driver in drivers" :key="driver" :value="driver">
                                            {{ driver.firstName }} {{ driver.lastName }}
                                          </option>
                                      </select>
                                      <select v-else  v-model="driverInfo" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                                          <option >{{ $t('SelectDriver') }}</option>
                                          <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                                            {{ driver.firstName }} {{ driver.lastName }}
                                          </option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="flex flex-wrap -mx-3">
                      <div class="w-full px-3">
                          <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                              {{ $t('Uploadcargodocument') }}
                          </label>
                          <div class="flex items-center justify-center w-full mb-5">
                              <label
                                  @drop="onDrop"
                                  @dragover="dragover"
                                  @dragleave="dragleave"
                                  for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                      <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                      </svg>
                                      <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">
                                          {{ $t('Clicktoupload') }}
                                        </span>
                                         {{ $t('ordraganddrop') }}
                                        </p>
                                      <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                  </div>
                                  <input  @change="handleInputFiles($event)" id="dropzone-file" type="file" class="hidden" multiple />
                              </label>
                          </div>
                      </div>
                  </div>

                  <div v-if="selectedFiles.length" class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal">
                        <thead>
                          <tr>
                            <th class="px-5 py-5 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              {{ $t('FilesName') }}
                            </th>
                            <th class="px-5 py-5 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                              {{ $t('ACTION') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr  v-for="(file, index) in selectedFiles " :key="index">
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap">{{ file.name }}</p>
                            </td>
            
                            <td>
                              <div class="w-fit	m-auto">
                                <div class="flex flex-row text-center items-center justify-items-center">
                                  <svg @click="deleteFile(file.name)" class="cursor-pointer" width="30px" height="30px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#e60000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 12V17" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 12V17" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#e60000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="flex items-center justify-between">
                    <button v-if="!showFormEditShipment" @click="addShipment" :class="roleClass" class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                      {{ $t('Save') }}
                    </button>
                    <button v-else @click="editShipmentById" class="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                      {{ $t('Edit') }}
                    </button>  
                    <a @click="returnToGridShipment" class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                      {{ $t('Return') }}
                    </a>
                  </div>
                </form>
                <div v-if="isShowView" class="fixed inset-0 flex items-center justify-center z-50">
                  <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                  <div class="relative w-2/3  p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                      <button  @click="cancelShowView" class="bg-slate-100 mt-[-30px] text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
                          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span class="sr-only">{{ $t('Closemodal') }}</span>
                      </button>
                      <div class="w-full">
                        <h2>{{ $t('ShowDocs') }}</h2>
                      </div>
                  </div>
                </div>
              </div>
            </main>
        </div>
    </div>
  </template>
  <script lang="ts" setup>
  import shipmentService from '../services/shipmentService'
  import SidebarView from './partials/SidebarView.vue'
  import HeaderView from './partials/HeaderView.vue'
  import statusService from '../services/statutService'
  import trailerService from '../services/trailerService'
  import driverListService from '../services/DriverListService'
  import companyService from '../services/companyService'
  import { ref , onMounted , Ref, computed } from 'vue'
    
  const destinationCountry:any = ref( [{ name: 'Usa' }])
  const destinationCity:any = ref( [{ name: 'Casa' },{ name: 'Tanger' }])
  const departCountry:any = ref( [{ name: 'Usa' }])
  const departCity:any = ref( [{ name: 'Marrakech' },{ name: 'Agadir' }])

  const statuts:any = ref([])
  const tracks:any = ref([])
  const drivers:any = ref([])
  const trailers:any = ref([])
  
  const isShowView = ref(false) 
  const shipments:any = ref([])
  const rawFiles:any = ref([])
  const showAddShipment = ref(false)
  const locationStartCountry:any = ref([])
  const locationStartCity:any = ref([])
  const locationEndCountry:any = ref([])
  const locationEndCity:any = ref([])
  const driverInfo:any = ref([])
  const trackInfo:any = ref([])
  const statusInfo:any = ref([])
  const trailersInfo:any = ref([])
  
  const showTypeRole = ref(0)

onMounted(() => {
    const item = localStorage.getItem('type');
    showTypeRole.value = Number(item)
})

const roleClass = computed(() => {
  if (showTypeRole.value === 3) {
    return 'asideBarSuperAdmin';
  } else if (showTypeRole.value === 2) {
    return 'asideBarAdmin';
  } else if (showTypeRole.value === 1) {
    return 'asideBarDriver';
  }  else if (showTypeRole.value === 4) {
    return 'asideBarSuperSuperAdmin';
  } 
  else {
    return '';
  }
});

  const infoShipment = ref({
    dateStart : ref('2024-03-06T12:47:58.314Z') ,
    dateEnd : ref('2024-03-06T12:47:58.314Z') ,
    locationStart : ref(''),
    locationEnd : ref(''),
    identifier: ref(''),
    statusName: ref(''),
    statusId: ref(0),
    trackId: ref(0),
    trackIdentifier: ref(''),
    userDriverId: ref(0),
    userDriverFullName: ref(''),
    userDriverHelperId: ref(1),
    userDriverHelperFullName: ref(''),
    userAdminId: ref(1),
    userAdminFullName: ref(''),
    remark: ref('remark'),
    MyselectedFiles: ref([]) as Ref<File[]>
  })
  const selectedDate = ref(null)
  const isModalDelete = ref(false)
  const showFormEditShipment = ref(false)
  const showIdShipment = ref(null)
  const showShipmentEdit = ref({
    dateStart : ref('2024-03-06T12:47:58.314Z') ,
    dateEnd : ref('2024-03-06T12:47:58.314Z') ,
    locationStart : ref(''),
    locationEnd : ref(''),
    identifier: ref(''),
    statusName: ref(''),
    statusId: ref(0),
    trackId: ref(0),
    trackIdentifier: ref(''),
    userDriverId: ref(0),
    userDriverFullName: ref(''),
    userDriverFullNamee: ref(''),
    userDriverHelperId: ref(1),
    userDriverHelperFullName: ref(''),
    userAdminId: ref(1),
    userAdminFullName: ref(''),
    remark: ref('remark'),
    MyselectedFiles: ref([]) as Ref<File[]>,
    id:ref(null)
  })
  const selectedFiles: Ref<File[]> = ref([]);
  const shipmentEditInfo = ref([])

function onDrop(event: DragEvent) {
  event.preventDefault()
  let isValid = true;
  const allowedExtensions = /(\.rsf|\.png)$/i;
  const files : any = event.dataTransfer?.files;

  for(const file of files){
    if(!allowedExtensions.exec(file.name)){
      isValid = false
    }
  }
  if (isValid) {
    const files = event.dataTransfer?.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!selectedFiles.value.some(existingFile => existingFile.name === file.name)) {
            selectedFiles.value.push(file);
          }
      }
    }
    // alert('bgha idkhal l save')
    // handleInputFiles(event)
    // saveFiles(e.dataTransfer.files)

  } else {
    alert('Please import valid run files')
    // toast.warning(`Please import valid run files`, {
    //     position: 'top-right',
    //     dismissible: false,
    //     queue: true,
    // })
    // e.currentTarget.classList.remove('bg-gray-300')
    // e.currentTarget.classList.add('border-2 border-dashed border-[#F5F5F5]')
  }
  // importText.value = '<span class="text-primary text-base">Drag or click here</span> to add files'
}

const handleInputFiles = (event: Event) => {

  const input = event.target as HTMLInputElement;
  if (input.files) {
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files[i];
      if (!selectedFiles.value.some(existingFile => existingFile.name === file.name)) {
            selectedFiles.value.push(file);
          }
    }
  }
  // saveFiles(event.target.files)
}

function dragover(event: any) {
  event.preventDefault()
  if (!event.currentTarget.classList.contains('bg-gray-300')) {
    event.currentTarget.classList.remove('bg-gray-100')
    event.currentTarget.classList.add('bg-gray-300')
    // importText.value = '<span class="text-primary text-base">Drop here</span> to add files'
  }
}

function dragleave(event: any) {
  event.currentTarget.classList.add('bg-gray-100')
  event.currentTarget.classList.remove('bg-gray-300')
  // importText.value = '<span class="text-primary text-base">Drag or click here</span> to add files'
}
const saveFiles = (files: any[]) => {
   console.log('sasa')
}

const deleteFile = (itemName: string) => {
  const index = selectedFiles.value.findIndex((item) => item.name === itemName);
  if (index !== -1) {
    selectedFiles.value.splice(index, 1);
  }
};
  
const getShipment = () => {
  showFormEditShipment.value = false
  shipmentService.getShipment()
  .then(( response: any ) => {
    shipments.value = response.data
  })
  .catch((error:any) => {
    console.log(error)
  });
}
const getStatus = () => {
  statusService.getStatus()
  .then(( response: any ) => {
    statuts.value = response.data
  })
  .catch((error:any) => {
    console.log(error)
  });
}
const getTracks = () => {
  companyService.getTrack()
  .then(( response: any ) => {
    tracks.value = response.data
  })
  .catch((error:any) => {
    console.log(error)
  });
}
const getDriver = () => {
  driverListService.getDriverList()
  .then(( response: any ) => {
    drivers.value = response.data
  })
  .catch((error:any) => {
    console.log(error)
  });
}
const getTrailer = () => {
  trailerService.getTrailers()
  .then(( response: any ) => {
    trailers.value = response.data
  })
  .catch((error:any) => {
    console.log(error)
  });
}
const showFormShipment = () => {
    // getStatus()
    // getTracks()
    // getDriver()
    getTrailer()
    showAddShipment.value = true
}
const addShipment = () => {
  infoShipment.value.locationStart =  `Country:${locationStartCountry.value.name}/ City:${locationStartCity.value.name}` 
  infoShipment.value.locationEnd =  `Country:${locationEndCountry.value.name} /City: ${locationEndCity.value.name}`
  infoShipment.value.userDriverId  = driverInfo.value.id
  infoShipment.value.userDriverFullName  = `${driverInfo.value.firstName} ${driverInfo.value.lastName}`
  infoShipment.value.statusId  = statusInfo.value.id
  infoShipment.value.statusName  = statusInfo.value.name
  infoShipment.value.trackId  = trackInfo.value.id
  infoShipment.value.trackIdentifier  = trackInfo.value.identifier

  shipmentService.addShipment(infoShipment.value)
    .then(( response: any ) => {
      getShipment()
      showAddShipment.value = false
    })
    .catch((error:any) => {
      console.log(error)
    });
}
const returnToGridShipment = () => {
  showAddShipment.value = false
  showFormEditShipment.value = false
}
const showEditShipment = (item : any) => {
  getStatus()
  getTracks()
  getDriver()
  getTrailer()
  showIdShipment.value =item
  showAddShipment.value = true
  showFormEditShipment.value = true
  shipmentService.showEditShipmentById(showIdShipment.value)
    .then(( response: any ) => {
      shipmentEditInfo.value = response.data
      showShipmentEdit.value.identifier = response.data.identifier
      showShipmentEdit.value.trackIdentifier = response.data.trackIdentifier
      driverInfo.value = response.data.userDriverId
      trackInfo.value = response.data.trackId
      statusInfo.value = response.data.statusId
      trailersInfo.value = response.data.statusId
    })
    .catch((error:any) => {
      console.log(error)
    });
}
const editShipmentById = () => {

    let foundItemDriver= drivers.value.find(item => item.id === driverInfo.value);
    showShipmentEdit.value.userDriverId = foundItemDriver.id
    showShipmentEdit.value.userDriverFullName = `${foundItemDriver.firstName} ${foundItemDriver.lastName}`
    let foundItemStatus = statuts.value.find(item => item.id === statusInfo.value);
    showShipmentEdit.value.statusId = foundItemStatus.id
    showShipmentEdit.value.statusName = foundItemStatus.name
    let foundItemTrack = tracks.value.find(item => item.id === statusInfo.value);
    showShipmentEdit.value.trackId = foundItemTrack.id
    showShipmentEdit.value.trackIdentifier = foundItemTrack.identifier
    showShipmentEdit.value.id = showIdShipment.value
  // showShipmentEdit.value.statusId = shipmentEditInfo.value.statusId
  // showShipmentEdit.value.statusName = shipmentEditInfo.value.statusName

  

  // infoShipment.value.locationStart =  `Country:${locationStartCountry.value.name}/ City:${locationStartCity.value.name}` 
  // infoShipment.value.locationEnd =  `Country:${locationEndCountry.value.name} /City: ${locationEndCity.value.name}`
  // infoShipment.value.userDriverId  = driverInfo.value.id
  // infoShipment.value.userDriverFullName  = `${driverInfo.value.firstName} ${driverInfo.value.lastName}`
  // infoShipment.value.statusId  = statusInfo.value.id
  // infoShipment.value.statusName  = statusInfo.value.name
  // infoShipment.value.trackId  = trackInfo.value.id
  // infoShipment.value.trackIdentifier  = trackInfo.value.identifier

  shipmentService.editShipment(showIdShipment.value,showShipmentEdit.value)
    .then(( response: any ) => {
      // console.log('response')
      // console.log(response.data)
    })
    .catch((error:any) => {
      console.log(error)
    });


}
const confirmDelete  = (item : any) => {
  isModalDelete.value = true
  showIdShipment.value = item
}
const deleteItem = (item : any) => {
  shipmentService.deleteShipmentById(showIdShipment.value)
    .then(( response: any ) => {
      getShipment()
      showAddShipment.value = false
    })
    .catch((error:any) => {
      console.log(error)
    });
    showIdShipment.value = null
    isModalDelete.value = false

}
const cancelDelete = () =>{
  isModalDelete.value = false
  showIdShipment.value = null;
}
onMounted(() => {
  getShipment() 
})

const showView = () => {
  isShowView.value = true
}
const cancelShowView = () => {
  isShowView.value = false
}

</script>

<style scoped>
.asideBarSuperSuperAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgba(43,139,209,1) 100%);
}
.asideBarSuperAdmin {
    background: rgb(244, 247, 248);
    background: linear-gradient(90deg, rgb(103, 175, 112) 0%, rgb(41, 240, 94) 100%);
}
.asideBarAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgb(197, 97, 72) 100%);
}
.asideBarDriver {
    background: linear-gradient(90deg, rgb(119, 125, 195) 0%, rgb(28, 7, 153) 100%);
}

</style>