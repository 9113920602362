import http from '../common/http-common'



class TrailerService {

    addTrailer(data: object) {
        return http.post('Trailer', data)
    }
    deleteTrailerById(id: null) {
        return http.delete(`Trailer/${id}`)
    }
    showEditTrailerById(id: null) {
        return http.get(`Trailer/${id}`)
    }
    editTrailer(id: null, trailer: object) {
        return http.put(`Trailer/${id}`,trailer)
    }
    getTrailers() {
        return http.get('Trailer')
    }    
}
export default new TrailerService()