import http from '../common/http-common'



class DocumentService {

    addDocument(data: object) {
        return http.post('Document', data)
    }
    deleteDocumentById(id: null) {
        return http.delete(`Document/${id}`)
    }
    showEditDocumentById(id: null) {
        return http.get(`Document/${id}`)
    }
    editDocument(id: null, track: object) {
        return http.put(`Document/${id}`,track)
    }
    getDocument() {
        return http.get('Document')
    }    
}
export default new DocumentService()
