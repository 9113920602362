<template>
    <div>
        <div class="md:min-h-screen bg-gray-100 text-gray-800 antialiased px-4 py-20 flex flex-col justify-center sm:py-3">
            <div class="relative py-3 sm:max-w-xl w-full md:w-2/5 mx-auto text-center">
                <span class="text-2xl font-bold">Login to your account</span>
                <div class="relative mt-4 bg-white shadow-md sm:rounded-lg text-left">
                    

                <!-- <div id="alert-border-2" class="flex items-center p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800" role="alert">
                    <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>
                    <div class="ms-3 text-sm font-medium">
                       {{ errorLogin  }}
                    </div>
                    <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"  data-dismiss-target="#alert-border-2" aria-label="Close">
                    <span class="sr-only">Dismiss</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    </button>
                </div> -->



                    <div class="h-2 asideBar rounded-t-md"></div>
                    <div class="py-6 px-8">
                        <label class="block font-semibold">Username or Email</label>
                        <input v-model="loginInfo.userNameOrEmail" type="text" placeholder="Email" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-sky-400 rounded-md">
                        <label class="block mt-3 font-semibold">Password</label>
                        <input v-model="loginInfo.password" type="password" placeholder="Password" class=" border w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-sky-400 rounded-md">
                        <div class="flex justify-between items-baseline">
                            <button @click="loginUser" class="mt-4 asideBar text-white py-2 px-6 rounded-lg hover:bg-[#2B8BD1]">Login</button>
                            <!-- <a href="#" class="text-sm hover:underline">Forgot password?</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import UserService from '../services/userService'

const router = useRouter();

const loginInfo  = ref({
    userNameOrEmail:ref(''),
    password:ref(''),

})
const errorLogin = ref('')

const loginUser = () => {
    console.log(loginInfo.value)
    UserService.login(loginInfo.value)
    .then(( response: any ) => {

       localStorage.setItem("token", response.data.token);
       localStorage.setItem("type", response.data.user.type);
       router.push({ name: 'Statistics' });
    })
    .catch((error:any) => {
        // errorLogin.value = error.response.data
     console.log(error)
   });
}; 
</script>
<style>
.asideBar {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgba(43,139,209,1) 100%);
}
</style>