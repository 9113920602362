import http from '../common/http-common'



class CompanyService {

    addCompany(data: object) {
        return http.post('Company', data)
    }
    deleteCompanyById(id: null) {
        return http.delete(`Company/${id}`)
    }
    showEditCompanyById(id: null) {
        return http.get(`Company/${id}`)
    }
    editCompany(id: null, track: object) {
        return http.put(`Company/${id}`,track)
    }
    getCompanies() {
        return http.get('Company')
    }   
    searchCompany(name : string) {
        return http.get(`Track/GetByIdentifier/${name}`)
    }    

}
export default new CompanyService()
