import http from '../common/http-common'



class UserService {

    addRegistre(data: object) {
        return http.post('User/register', data)
    }
    login(data: object) {
        return http.post('User/authenticate', data)
    }
    deleteUserById(id: object) {
        return http.delete('User', id)
    }
    changeUserRequest(id: object) {
        return http.put('User', id)
    }
    showEditUserById(id: null) {
        return http.get(`User/${id}`)
    }
    editUser(trailer: object) {
        return http.put('User',trailer)
    }
    getUsers() {
        return http.get('User')
    }    
}
export default new UserService()