import axios from 'axios';
const token = localStorage.getItem("token");

const http = axios.create({
    baseURL: 'https://api.transpomanager.com/api/',
    // baseURL: 'http://31.220.89.29:5000/api/',
    // baseURL: 'https://localhost:7284/api',
    headers: {
        'Content-type': 'application/json',
         'Accept': 'text/plain',
        'Authorization': `Bearer ${token}` // Add the token to the Authorization header
    },

})

export default http




