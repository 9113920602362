<template>
      <div class="flex h-screen overflow-hidden">
        <!-- ===== Sidebar Start ===== -->
        <SidebarView />
        <!-- ===== Sidebar End ===== -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden" >
            <!-- ===== Header Start ===== -->
            <HeaderView />
            <!-- ===== Header Start ===== -->
            <main>
              <div class="w-11/12 mx-auto px-4 sm:px-8">
                <div v-if="isModalDelete" class="fixed inset-0 flex items-center justify-center z-50">
                  <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
                  <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                      <button  @click="cancelDelete" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
                          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span class="sr-only">Close modal</span>
                      </button>
                      <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                      <p class="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to delete this User?</p>
                      <div class="flex justify-center items-center space-x-4">
                          <button @click="cancelDelete" class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                              No, cancel
                          </button>
                          <button @click="deleteItem" class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                              Yes, I'm sure
                          </button>
                      </div>
                  </div>
                </div>
                <div v-if="!showAddUser" class="py-8">
                  <div class="w-full mx-auto grid grid-cols-1 lg:grid-cols-3 justify-between gap-2">
                    <div class="flex gap-5">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.4294 0H12.5583C12.6615 0.0234399 12.7443 0.0500052 12.806 0.0812585C16.7791 2.06818 20.7522 4.05355 24.7268 6.03657C24.8331 6.0897 24.9112 6.18033 24.9596 6.30769C24.9893 6.38426 25.0042 6.49755 25.0034 6.64679C25.0003 8.39072 24.9995 10.1354 25.0018 11.8794C25.0018 12.5349 24.0634 12.7365 23.8142 12.131C23.7689 12.0208 23.7462 11.8301 23.747 11.5582C23.7494 10.2417 23.7501 8.9228 23.7478 7.60157C23.7478 7.58907 23.7353 7.58204 23.7243 7.58673C20.1248 9.3877 16.5228 11.1894 12.9193 12.9928C12.649 13.1279 12.4685 13.1912 12.2052 13.0592C10.4597 12.1794 8.71106 11.3035 6.9601 10.4308C6.94057 10.4206 6.93119 10.4269 6.93119 10.448C6.9265 11.6379 6.92572 12.8271 6.92963 14.0171C6.93119 14.5242 6.43973 14.8195 5.99047 14.5875C5.74826 14.4624 5.68184 14.2124 5.68106 13.9327C5.68028 12.5583 5.68028 11.1879 5.68106 9.82212C5.68106 9.79946 5.6709 9.78305 5.65059 9.77289L1.27591 7.5836C1.26341 7.57813 1.24935 7.58673 1.24935 7.60079V17.7229C1.24935 17.7433 1.25872 17.7589 1.2767 17.7698C4.88176 19.7732 8.48682 21.7773 12.0911 23.7806C12.9201 24.2408 12.331 25.3472 11.4723 24.869C7.75471 22.8 4.03558 20.7326 0.316439 18.666C0.146109 18.5715 0.0406292 18.4168 0 18.2035V6.40848C0.130482 6.04594 0.352381 6.00219 0.66335 5.84748C4.51297 3.91994 8.36337 1.99318 12.2138 0.0679758C12.2661 0.0414106 12.338 0.0195333 12.4302 0M2.06115 6.54521C2.04631 6.55224 2.04631 6.57334 2.06115 6.58037L6.29519 8.69934C6.30066 8.70247 6.30769 8.70247 6.31238 8.69934L16.7424 3.48083C16.7572 3.4738 16.7572 3.4527 16.7424 3.44567L12.5052 1.32904C12.4997 1.32592 12.4927 1.32592 12.488 1.32904L2.06115 6.54521ZM7.73674 9.38613C7.72346 9.39317 7.72346 9.4127 7.73674 9.41973L12.4943 11.8012C12.4997 11.8036 12.5052 11.8036 12.5107 11.8012L22.9485 6.58037C22.9618 6.57334 22.9618 6.55381 22.9485 6.54678L18.1847 4.16606C18.1792 4.16371 18.1738 4.16371 18.1683 4.16606L7.73674 9.38613Z" fill="black"/>
                        <path d="M30.0008 21.5983V22.153C29.9226 23.8899 29.3827 25.4182 28.3795 26.7371C26.8356 28.767 24.7541 29.8546 22.1343 29.9999H21.6015C19.1582 29.8671 17.1697 28.8928 15.6376 27.077C14.6218 25.8737 14.0132 24.4744 13.8139 22.8789C13.1287 17.4088 18.0269 12.8364 23.4524 13.9045C27.1817 14.639 29.8226 17.826 30.0008 21.5983ZM28.7483 21.8749C28.7483 18.0792 25.6706 15.0023 21.8757 15.0023C18.0808 15.0023 15.0031 18.0799 15.0031 21.8749C15.0031 25.6698 18.0808 28.7475 21.8757 28.7475C25.6706 28.7475 28.7483 25.6698 28.7483 21.8749Z" fill="black"/>
                      </svg>
                      <h2 class="text-2xl font-semibold leading-tight">
                        {{ $t('USERS') }}
                      </h2>
                    </div>
                    <div class="flex justify-center pb-1 lg:pb-4  bg-white dark:bg-gray-900">
                      <div class="w-full">
                          <label for="table-search" class="sr-only">Search</label>
                          <div class="relative mt-1">
                              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                  </svg>
                              </div>
                              <input type="text" id="table-search" class="w-full block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg  bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
                          </div>
                      </div>
                    </div>
                    <div class="flex  justify-start lg:justify-end mb-3">
                      <button  @click="showFormUser" :class="roleClass" class="text-white focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-base font-bold p-1 text-center inline-flex items-center px-2 py-2 lg:py-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg class="w-5 h-5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                          <path d="M11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44771 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z"/>
                        </svg>
                        {{ $t('AddUser') }}
                      </button>
                    </div>
                  </div>
                  <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                      <table class="min-w-full leading-normal text-center">
                        <thead :class="roleClass" >
                          <tr   >
                            <th class="px-5 py-5 border-b-2  text-center text-xs font-semibold text-white uppercase tracking-wider">
                              Created
                            </th>
                            <th class="px-5 py-5 border-b-2 text-center text-xs font-semibold text-white uppercase tracking-wider">
                              Name
                            </th>
                            <th class="px-5 py-5 border-b-2  text-center text-xs font-semibold text-white uppercase tracking-wider">
                              Email
                            </th>
                            <th class="px-5 py-5 border-b-2  text-center text-xs font-semibold text-white uppercase tracking-wider">
                              Role
                            </th>
                            <th class="px-5 py-5 border-b-2 text-center text-xs font-semibold text-white uppercase tracking-wider">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="user in users" :key="user.id">
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap"> {{ user.id}} </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap"> {{ user.userName}} </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap"> {{ user.email}} </p>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <p class="text-gray-600 whitespace-no-wrap"> {{ formatType(user.type)}} </p>
                            </td>
                            <td>
                              <div class="w-fit	m-auto">
                                <div class="flex flex-row text-center items-center justify-items-center">
                                  <svg  @click="showEditUser(user.userName)" class="cursor-pointer" width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#07d514" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#07d514" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <form v-if="showAddUser" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                  <h3 v-if="!showFormEditUser" class="text-gray-700 font-bold">Add new User</h3>
                  <h1 v-else class="text-gray-700 font-bold">Edit User</h1>
                  <div class="flex flex-col justify-center items-center mt-6">
                    <img class="w-28 h-28 rounded-full" src="../assets/images/profil.png" alt="">
                      <label class="w-20 flex flex-col items-center mt-[-13px] cursor-pointer">
                          <img class="w-8" src="../assets/images/cam.png" alt="Description de l'image">
                          <input type='file' class="hidden" />
                          
                          <span class="text-gray-700 text-sm font-bold">ANAS AIT MOUMEN</span>
                      </label>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                        First Name
                      </label>
                      <input v-if="!showFormEditUser" v-model="infoUser.firstName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                      <input v-else v-model="showUserEdit.firstName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                        LastName
                      </label>
                      <input v-if="!showFormEditUser" v-model="infoUser.lastName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                      <input v-else v-model="showUserEdit.lastName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                          Full Name
                      </label>
                      <input v-if="!showFormEditUser" v-model="infoUser.userName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                      <input v-else v-model="showUserEdit.userName" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                          Email
                      </label>
                      <input v-if="!showFormEditUser" v-model="infoUser.email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                      <input v-else v-model="showUserEdit.email" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label class="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-mark">
                      PassWord
                    </label>
                    <div class="flex items-center border-b">
                      <div class="relative appearance-none block w-full ">
                        <input id="password"
                          class="mt-1 p-2 pr-10appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          :type="passwordFieldType" v-model="infoUser.password"
                        />
                        <span @click="togglePasswordVisibility" class="absolute cursor-pointer inset-y-0 right-0 pr-3 flex items-center">
                          <svg v-if="showPassword" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Edit / Show"> <g id="Vector"> <path d="M3.5868 13.7788C5.36623 15.5478 8.46953 17.9999 12.0002 17.9999C15.5308 17.9999 18.6335 15.5478 20.413 13.7788C20.8823 13.3123 21.1177 13.0782 21.2671 12.6201C21.3738 12.2933 21.3738 11.7067 21.2671 11.3799C21.1177 10.9218 20.8823 10.6877 20.413 10.2211C18.6335 8.45208 15.5308 6 12.0002 6C8.46953 6 5.36623 8.45208 3.5868 10.2211C3.11714 10.688 2.88229 10.9216 2.7328 11.3799C2.62618 11.7067 2.62618 12.2933 2.7328 12.6201C2.88229 13.0784 3.11714 13.3119 3.5868 13.7788Z" stroke="#000000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" stroke="#000000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g> </g></svg>
                          <svg v-else width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Edit / Hide"> <path id="Vector" d="M3.99989 4L19.9999 20M16.4999 16.7559C15.1473 17.4845 13.6185 17.9999 11.9999 17.9999C8.46924 17.9999 5.36624 15.5478 3.5868 13.7788C3.1171 13.3119 2.88229 13.0784 2.7328 12.6201C2.62619 12.2933 2.62616 11.7066 2.7328 11.3797C2.88233 10.9215 3.11763 10.6875 3.58827 10.2197C4.48515 9.32821 5.71801 8.26359 7.17219 7.42676M19.4999 14.6335C19.8329 14.3405 20.138 14.0523 20.4117 13.7803L20.4146 13.7772C20.8832 13.3114 21.1182 13.0779 21.2674 12.6206C21.374 12.2938 21.3738 11.7068 21.2672 11.38C21.1178 10.9219 20.8827 10.6877 20.4133 10.2211C18.6338 8.45208 15.5305 6 11.9999 6C11.6624 6 11.3288 6.02241 10.9999 6.06448M13.3228 13.5C12.9702 13.8112 12.5071 14 11.9999 14C10.8953 14 9.99989 13.1046 9.99989 12C9.99989 11.4605 10.2135 10.9711 10.5608 10.6113" stroke="#000000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                        </span>
                      </div>
                      <span @click="generatePassword"  class="flex-shrink-0 font-bold cursor-pointer border-transparent border-4 text-black-500 hover:text-sky-600 text-sm py-1 px-2 rounded" >
                        Generate
                      </span>
                    </div>
                  </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                          Phone
                      </label>
                      <input v-if="!showFormEditUser" v-model="infoUser.phoneNumber" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                      <input v-else v-model="showUserEdit.phoneNumber" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  id="grid-model" type="text">
                    </div>
                  </div>
                  <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                      <label class="block uppercase text-left tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-model">
                          Role
                      </label>
                      <div>
                          <select v-model="infoUser.role" id="example-select" class="w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                              <option value="0">None</option>
                              <option value="1">Driver</option>
                              <option value="2">Admin</option>
                              <option value="3">SuperAdmin</option>
                              <option value="4">SuperSuperAdmin</option>
                              <option value="5">Company</option>
                          </select>
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center justify-between">
                    <button v-if="!showFormEditUser" @click="addUser" :class="roleClass" class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                      Save
                    </button>
                    <button v-else @click="editUserById" class="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                      save
                    </button>  
                    <a @click="returnToGridUser" class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
                      Return
                    </a>
                  </div>
                </form>
              </div>
            </main>
        </div>
      </div>

  </template>
  <script lang="ts" setup>
  import userservice from '../services/userService'
  import SidebarView from './partials/SidebarView.vue'
  import HeaderView from './partials/HeaderView.vue'

  import { ref , onMounted , Ref , PropType , defineProps, computed} from 'vue'
  
  const users:any = ref([])
  const showAddUser = ref(false)
  const infoUser = ref({
    firstName : ref(''),
    lastName : ref(''),
    userName: ref(''),
    email: ref(''),
    phoneNumber: ref(''),
    password:ref(),
    role: ref(''),
    type:ref(4)
  })

  const isModalDelete = ref(false)
  const showFormEditUser = ref(false)
  const showUserName = ref(null)
  const showIdUser = ref(null)
  const selectedOption = ref('Default')

  const showUserEdit = ref({
    currentEmailOrUserName : ref(''),
    firstName : ref(''),
    lastName : ref(''),
    userName: ref(''),
    email: ref(''),
    phoneNumber: ref(null),
    password:ref(null),
    role: ref(''),
    type:ref(4),
    id:ref(null)
  
  })
  const iddelete = ref({
    id:ref(null)
  })
  const passwordFieldType:any = ref('password')
  const showPassword:any = ref(false)
  const password:any = ref('')
  const selectedDate = ref(null)
  const searchQuery = ref('')
  const selectedFiles: Ref<File[]> = ref([]);

  const showTypeRole = ref(0)

onMounted(() => {
    const item = localStorage.getItem('type');
    showTypeRole.value = Number(item)
})

 
const roleClass = computed(() => {
  if (showTypeRole.value === 3) {
    return 'asideBarSuperAdmin';
  } else if (showTypeRole.value === 2) {
    return 'asideBarAdmin';
  } else if (showTypeRole.value === 1) {
    return 'asideBarDriver';
  }  else if (showTypeRole.value === 4) {
    return 'asideBarSuperSuperAdmin';
  } 
  else {
    return '';
  }
});

  
  const formatType = (type : any) => {
          switch(type) {
            case 0: return 'None';
            case 1: return 'Driver';
            case 2: return 'Admin';
            case 3: return 'SuperAdmin';
            case 4: return 'SuperSuperAdmin';
            case 5: return 'Company';
            default: return 'Unknown';
          }
        }
        
const deleteFile = (itemName: string) => {
  const index = selectedFiles.value.findIndex((item) => item.name === itemName);
  if (index !== -1) {
    selectedFiles.value.splice(index, 1);
  }
};
  
  const togglePasswordVisibility = () =>{
  showPassword.value = !showPassword.value;
  passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
}
  const getUsers = () => {
    showFormEditUser.value = false
    userservice.getUsers()
    .then(( response: any ) => {
      users.value = response.data
    })
    .catch((error:any) => {
      console.log(error)
    });
  }
  
  const showFormUser = () => {
      showAddUser.value = true
  }
  const addUser = () => {
    console.log(infoUser.value)
    userservice.addRegistre(infoUser.value)
      .then(( response: any ) => {
        getUsers()
        showAddUser.value = false
      })
      .catch((error:any) => {
        console.log(error)
      });
  }
  const returnToGridUser = () => {
    showAddUser.value = false
    showFormEditUser.value = false
  }
  const showEditUser  = (item : any) => {
    console.log(item)
    showUserName.value =item
    showAddUser.value = true
    showFormEditUser.value = true
    showUserEdit.value.currentEmailOrUserName = item
    userservice.showEditUserById(showUserName.value)
      .then(( response: any ) => {
        showUserEdit.value.firstName = response.data.firstName
        showUserEdit.value.lastName = response.data.lastName
        showUserEdit.value.userName = response.data.userName
        showUserEdit.value.email = response.data.email
        showUserEdit.value.phoneNumber = response.data.phoneNumber
        showUserEdit.value.password = response.data.password
        showUserEdit.value.type = response.data.type


    // phoneNumber: ref(''),
    // :ref(),
    // role: ref(''),

      })
      .catch((error:any) => {
        console.log(error)
      });
  }
  const editUserById = () => {
    console.log(showIdUser.value)
    showUserEdit.value.id = showIdUser.value
    userservice.editUser(showUserEdit.value)
      .then(( response: any ) => {
        console.log('response')
        console.log(response.data)
      })
      .catch((error:any) => {
        console.log(error)
      });
  }
  const confirmDelete  = (item : any) => {
    isModalDelete.value = true
    showIdUser.value = item;
  }
  const deleteItem = () => {
    iddelete.value.id = showIdUser.value
    console.log(showIdUser.value)
    userservice.deleteUserById(iddelete.value)
      .then(( response: any ) => {
        getUsers()
        showAddUser.value = false
      })
      .catch((error:any) => {
        console.log(error)
      });
      showIdUser.value = null
      isModalDelete.value = false
  
  }
  const cancelDelete = () =>{
    isModalDelete.value = false
    showIdUser.value = null;
  }
  onMounted(() => {
    getUsers()
  })

  const generatePassword = () =>{
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
      const passwordLength = 12; // You can adjust the length as needed
      let newPassword = '';

      for (let i = 0; i < passwordLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        newPassword += characters.charAt(randomIndex);
      }

      infoUser.value.password = newPassword;
    }
  </script>
  <style scoped>
  
.asideBarSuperSuperAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgba(43,139,209,1) 100%);
}
.asideBarSuperAdmin {
    background: rgb(244, 247, 248);
    background: linear-gradient(90deg, rgb(103, 175, 112) 0%, rgb(41, 240, 94) 100%);
}
.asideBarAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgb(197, 97, 72) 100%);
}
.asideBarDriver {
    background: linear-gradient(90deg, rgb(119, 125, 195) 0%, rgb(28, 7, 153) 100%);
}
  </style>