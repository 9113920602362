<template>
    <div class="flex h-screen overflow-hidden">
        <!-- ===== Sidebar Start ===== -->
        <SidebarView />
        <!-- ===== Sidebar End ===== -->
        <div class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden" >
            <!-- ===== Header Start ===== -->
            <HeaderView />
            <!-- ===== Header Start ===== -->
            <main>
                <div>
                    <div class="w-11/12 mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5" >
                            <div :class="roleClass" class="rounded-lg cursor-pointer border border-stroke text-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark" >
                                <div class="mt-1 ml-7 flex items-end justify-between">
                                    <div>
                                        <h4 class="text-lg  text-white">
                                             {{ $t('Users') }} 
                                        </h4>
                                        <span class="text-xl font-bold">+ 255</span><br>
                                        <span class="text-lg">{{ $t('AddedThisWeek') }} </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="roleClass" class="rounded-lg border cursor-pointer border-stroke text-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark" >
                                <div class="mt-1 ml-7 flex items-end justify-between">
                                    <div>
                                        <h4 class="text-lg  text-white">
                                            Truck {{ $t('Statistics') }} 
                                        </h4>
                                        <span class="text-xl font-bold">+ 255</span><br>
                                        <span class="text-lg">{{ $t('AddedThisWeek') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div :class="roleClass" class="rounded-lg border cursor-pointer border-stroke text-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark" >
                                <div class="mt-1 ml-7 flex items-end justify-between">
                                    <div>
                                        <h4 class="text-lg  text-white">
                                            {{ $t('Shipment') }}
                                        </h4>
                                        <span class="text-xl font-bold">+ 255</span><br>
                                        <span class="text-lg">{{ $t('AddedThisWeek') }} </span>
                                    </div>
                                </div>
                            </div>
                            <div :class="roleClass" class="rounded-lg cursor-pointer border border-stroke text-white px-7.5 py-6 shadow-default dark:border-strokedark dark:bg-boxdark" >
                                <div class="mt-1 ml-7 flex items-end justify-between">
                                    <div>
                                        <h4 class="text-lg text-white">
                                            {{ $t('Driver') }}
                                        </h4>
                                        <span class="text-xl font-bold">+ 255</span><br>
                                        <span class="text-lg">{{ $t('AddedThisWeek') }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import SidebarView from './partials/SidebarView.vue'
import HeaderView from './partials/HeaderView.vue'

    const isActive = ref(false)
    const showTypeRole = ref(0)

    onMounted(() => {
        const item = localStorage.getItem('type');
        showTypeRole.value = Number(item)
    })

    const roleClass = computed(() => {
      if (showTypeRole.value === 3) {
        return 'asideBarSuperAdmin';
      } else if (showTypeRole.value === 2) {
        return 'asideBarAdmin';
      } else if (showTypeRole.value === 1) {
        return 'asideBarDriver';
      }  else if (showTypeRole.value === 4) {
        return 'asideBarSuperSuperAdmin';
      } 
      else {
        return '';
      }
    });
    function checkStatus() {
        fetch("http://31.220.89.29:5000/api/Status", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            mode: "cors" // Assurez-vous d'utiliser 'cors'
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok " + response.statusText);
            }
            return response.json();
        })
        .then(data => {
            console.log("Success:", data);
        })
        .catch(error => {
            console.error("Error:", error);
        });
    }
    onMounted(() => {
        checkStatus()
  })

// Call the function to check the status

</script>
<style>

.asideBarSuperSuperAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgba(147,186,215,1) 0%, rgba(43,139,209,1) 100%);
}
.asideBarSuperAdmin {
    background: rgb(244, 247, 248);
    background: linear-gradient(90deg, rgb(103, 175, 112) 0%, rgb(41, 240, 94) 100%);
}
.asideBarAdmin {
    background: rgb(147,186,215);
    background: linear-gradient(90deg, rgb(216, 168, 147) 0%, rgb(197, 97, 72) 100%);
}
.asideBarDriver {
    background: linear-gradient(90deg, rgb(119, 125, 195) 0%, rgb(28, 7, 153) 100%);
}
</style>