import http from '../common/http-common'



class DocumentTypeService {

    addDocumentType(data: object) {
        return http.post('DocumentType', data)
    }
    deleteDocumentTypeById(id: null) {
        return http.delete(`DocumentType/${id}`)
    }
    showEditDocumentTypeById(id: null) {
        return http.get(`DocumentType/${id}`)
    }
    editDocumentType(id: null, documentType: object) {
        return http.put(`DocumentType/${id}`,documentType)
    }
    getDocumentType() {
        return http.get('DocumentType')
    }    
}
export default new DocumentTypeService()
