import http from '../common/http-common'



class TrackService {

    addTrack(data: object) {
        return http.post('Track', data)
    }
    deleteTrackById(id: null) {
        return http.delete(`Track/${id}`)
    }
    showEditTrackById(id: null) {
        return http.get(`Track/${id}`)
    }
    editTrack(id: null, track: object) {
        return http.put(`Track/${id}`,track)
    }
    getTracks() {
        return http.get('Track')
    }   
    searchTrack(name : string) {
        return http.get(`Track/GetByIdentifier/${name}`)
    }    

}
export default new TrackService()
