import http from '../common/http-common'



class UserRole {

    addRegistre(data: object) {
        return http.post('User/register', data)
    }

    deleteRoleById(id: object) {
        return http.delete('Role', id)
    }
    showEditRoleById(id: null) {
        return http.get(`Role/${id}`)
    }
    editRole(id: null, trailer: object) {
        return http.put(`Role/${id}`,trailer)
    }
    getRoles() {
        return http.get('Role')
    }    
}
export default new UserRole()